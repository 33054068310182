<template>
    <!--begin::Dashboard-->
    <div class="card card-custom col-lg-13 col-xxl-12 mt-5 p-5">
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <div class="d-flex justify-content-around flex-wrap">
                <div class="mt-1 d-flex flex-column" style="width: 20%">
                    <router-link :to="{ name: 'admin/dashboard', params: { id: this.$route.params.id } }" replace>
                        <span class="btn btn-outline-danger btn-md">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            {{ $t('CONTROLLERS.CANCEL') }}
                        </span>
                    </router-link>
                    <img :src="miniature" style="max-width: 100%" class="mx-auto rounded" />
                    <label class="mt-2 btn btn-primary btn-md btn-block" for="miniature">{{ $t('MEDIA.SELECT') }}</label>
                    <input
                        type="file"
                        ref="miniature"
                        id="miniature"
                        name="miniature"
                        accept="image/*"
                        @change="onMiniatureChange"
                        style="display: none"
                    />
                </div>

                <b-form-group style="width: 70%">
                    <b-form-group id="input-group-1" :label="$t('CLASS.NAME.LABEL') + ' *'" label-for="input-1">
                        <b-form-input id="input-1" v-model="form.title" type="text" required :placeholder="$t('CLASS.NAME.INPUT')">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-2" :label="$t('CLASS.DESCRIPTION.LABEL') + ' *'" label-for="input-2">
                        <b-form-textarea
                            id="input-2"
                            v-model="form.description"
                            type="text"
                            rows="3"
                            max-rows="6"
                            required
                            :placeholder="$t('CLASS.DESCRIPTION.INPUT')"
                        >
                        </b-form-textarea>
                    </b-form-group>

                    <b-form-group id="input-group-3" :label="$t('CLASS.CERTIFICATE.LABEL') + ' *'" label-for="input-3">
                        <b-form-textarea
                            id="input-3"
                            v-model="form.text_certificate"
                            type="text"
                            rows="3"
                            max-rows="6"
                            required
                            :placeholder="$t('CLASS.CERTIFICATE.INPUT')"
                        >
                        </b-form-textarea>
                    </b-form-group>
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="w-50 d-flex align-items-center justify-content-center">
                            <span class="svg-icon svg-icon-md mr-4">
                                <inline-svg src="/media/svg/files/pdf.svg"></inline-svg>
                            </span>
                            <p v-if="!file1Name" class="m-0">Pas de fiche synthèse ajoutée</p>
                            <p v-else class="m-0">{{ file1Name }}</p>
                        </div>
                        <div class="w-50 d-flex align-items-center justify-content-center">
                            <b-form-group id="input-group-4" label-for="input-4" class="w-100 m-0">
                                <label v-if="!form.summary" class="mt-4 btn btn-primary btn-md btn-block" for="file1"
                                    >Ajouter une fiche synthèse (.pdf)</label
                                >
                                <label v-else class="mt-4 btn btn-primary btn-md btn-block" for="file1"
                                    >Modifier la fiche synthèse (.pdf)</label
                                >
                                <input
                                    type="file"
                                    ref="file1"
                                    id="file1"
                                    name="file1"
                                    accept="application/pdf"
                                    style="display: none"
                                    @change="onSummaryChange"
                                />
                            </b-form-group>
                        </div>
                    </div>
                    <b-alert v-model="showPdfSizeAlert" variant="danger"> Votre fichier dépasse la taille maximale (2Mo). </b-alert>
                    <b-alert v-model="showPdfTypeAlert" variant="danger"> Votre fichier n'est pas un PDF valide. </b-alert>
                    <b-form-group id="input-group-4" :label="$t('CLASS.TEASER.LABEL')" label-for="input-4">
                        <b-form-input
                            id="input-4"
                            v-model="form.teaser"
                            type="text"
                            :placeholder="$t('CLASS.TEASER.INPUT')"
                            @keyup="isValidUrl(form.teaser)"
                        >
                        </b-form-input>
                    </b-form-group>
                    <b-alert v-model="alertURL" variant="danger"> Votre lien ne correspond pas une vidéo youtube. </b-alert>
                    <b-form-group class="w-100" :label="$t('CLASS.THEME.LABEL')" label-for="selectTheme">
                        <b-form-select v-model="form.selectedTheme" :options="themesOptions" id="selectTheme"></b-form-select>
                    </b-form-group>
                </b-form-group>

                <b-form-group
                    class="border-top border-bottom border-secondary py-7 m-0 w-100 d-flex align-items-center justify-content-center flex-column"
                    style="margin: 0 5rem !important"
                >
                    <b-row class="form-group d-flex justify-content-around align-items-center w-100">
                        <div style="width: 50%">
                            <b-form-checkbox :disabled="form.scorm" name="linear" v-model="form.linear" id="linear" switch>
                                {{ $t('CLASS.LINEAR.FALSE') }}
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                {{ $t('CLASS.LINEAR.INFOFALSE') }}
                            </p>
                        </div>
                        <div style="width: 50%" class="d-flex align-items-center flex-wrap">
                            <label class="m-0">{{ $t('CLASS.DURATION.LABEL') + '*' }}</label>
                            <b-form-input
                                type="number"
                                v-model="form.duration"
                                placeholder="60"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                size="sm"
                                class="ml-3"
                                style="width: 6rem"
                                required
                            >
                            </b-form-input>
                            <label class="m-0 ml-3">{{ $t('CLASS.DURATION.UNIT') }}</label>
                            <p class="text-muted text-left w-100">
                                {{ $t('CLASS.DURATION.INFO') }}
                            </p>
                        </div>
                    </b-row>
                    <b-row class="form-group" style="width: 100%">
                        <div style="width: 50%">
                            <b-form-checkbox name="catalog" v-model="form.catalog" id="catalog" switch>
                                <span v-if="form.catalog === true">{{ $t('CLASS.CATALOG.TRUE.LABEL') }}</span>
                                <span v-else>{{ $t('CLASS.CATALOG.FALSE.LABEL') }}</span>
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                <span v-if="form.catalog === true">{{ $t('CLASS.CATALOG.TRUE.INFO') }}</span>
                                <span v-else>{{ $t('CLASS.CATALOG.FALSE.INFO') }}</span>
                            </p>
                        </div>
                        <div style="width: 50%" class="d-flex align-items-center flex-wrap"></div>
                    </b-row>
                    <b-row class="form-group" style="width: 100%">
                        <div style="width: 50%">
                            <b-form-checkbox name="scorm" v-model="form.scorm" id="scorm" switch @change="setDefaultData">
                                <span>{{ $t('CLASS.SCORM.LABEL') }}</span>
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                <span>{{ $t('CLASS.SCORM.INFO') }}</span>
                            </p>
                        </div>
                        <div style="width: 50%" class="d-flex flex-column flex-wrap" v-if="form.scorm === true">
                            <div class="d-flex flex-row">
                                <b-form-file
                                    id="scorm_file"
                                    type="file"
                                    ref="scorm_file"
                                    name="scorm_file"
                                    :v-model="scorm_file"
                                    browse-text="Charger le Scorm"
                                    placeholder="Pas de fichier"
                                    accept=".zip"
                                    :style="{ outline: error.scorm ? '1px solid red' : '', borderRadius: '5px' }"
                                    @input="loadScorms"
                                />
                            </div>
                        </div>
                    </b-row>
                    <b-row class="form-group" style="width: 100%">
                        <div style="width: 50%">
                            <b-form-checkbox :disabled="form.scorm" name="classroom" v-model="form.classroom" id="classroom" switch>
                                <span>{{ $t('CLASS.CLASSROOM.LABEL') }}</span>
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                <span>{{ $t('CLASS.CLASSROOM.INFO') }}</span>
                            </p>
                        </div>
                        <div style="width: 50%" class="d-flex flex-column flex-wrap" v-if="form.classroom === true">
                            <div class="d-flex flex-row align-items-center">
                                <label class="m-0">{{ $t('CLASS.MAX_INTERNS.LABEL') + '*' }}</label>
                                <b-form-input
                                    type="number"
                                    v-model="form.max_intern"
                                    :value="form.max_intern"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    size="sm"
                                    class="ml-3"
                                    :required="form.classroom === true"
                                    style="width: 6rem"
                                >
                                </b-form-input>
                            </div>
                            <p class="text-muted text-left w-100">
                                {{ $t('CLASS.MAX_INTERNS.INFO') }}
                            </p>
                        </div>
                    </b-row>
                </b-form-group>
            </div>

            <div class="mt-5 text-center">
                <b-button
                    type="submit"
                    class="mx-5 btn-lg"
                    variant="primary"
                    :disabled="validURL === false || showPdfSizeAlert || showPdfTypeAlert"
                >
                    {{ $t('CONTROLLERS.VALIDATION') }}
                </b-button>
                <b-button type="reset" class="mx-5 btn-lg" variant="danger">{{ $t('CONTROLLERS.ERASE') }}</b-button>
            </div>
        </b-form>
        <div
            v-if="submit"
            style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
                background-color: #ffffff9e;
                backdrop-filter: blur(5px);
            "
            class="d-flex flex-column justify-content-around align-items-center"
        >
            <div style="z-index: 200" class="d-flex flex-column justify-content-around align-items-center">
                <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
                <p class="text-primary py-2 my-2">Enregistrement en cours ...</p>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import i18n from '@/core/plugins/vue-i18n.js';
import { mapGetters } from 'vuex';
import { LIST_ALL_THEMES } from '@/core/services/store/api/theme.service';

export default {
    name: 'admin-add-classes',
    data() {
        return {
            defaultMiniature: '/media/logos/academy.png',
            form: {
                title: '',
                user_id: '',
                miniature: '',
                description: '',
                verion: '',
                linear: true,
                catalog: false,
                scorm: false,
                teaser: '',
                on: false,
                text_certificate: '',
                duration: '',
                customer_id: '1',
                selectedTheme: null,
                classroom: false,
                max_intern: null
            },
            error: {
                scorm: false
            },
            show: true,
            submit: false,
            miniature: '',
            file1: '',
            file1Name: '',
            scorm_file: '',
            showPdfSizeAlert: false,
            showPdfTypeAlert: false,
            validURL: null,
            alertURL: false,
            themesOptions: []
        };
    },
    async mounted() {
        this.miniature = this.defaultMiniature;
        this.form.miniature = this.defaultMiniature;
        this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('MENU.DASHBOARD') }]);
        await this.$store.dispatch(LIST_ALL_THEMES).then((result) => {
            this.themesOptions = [
                {
                    value: null,
                    text: 'Sélectionnez un thème de formation'
                }
            ];
            result &&
                result.data.map((theme) => {
                    this.themesOptions.push({
                        value: theme.id,
                        text: theme.name
                    });
                });
        });
    },
    computed: {
        ...mapGetters({ themes: 'getAllThemes' })
    },
    methods: {
        checkData() {
            if (this.form.scorm === true && this.scorm_file === '') {
                this.error.scorm = true;
                this.$bvToast.toast('Veuillez ajouter un fichier scorm', {
                    title: 'Erreur',
                    variant: 'danger',
                    solid: true
                });
                return false;
            }
            if (this.form.classroom === true && this.form.max_intern == 0) {
                this.$bvToast.toast('Le nombre maximum de stagiaire ne peut pas être égal à 0', {
                    title: 'Erreur',
                    variant: 'danger',
                    solid: true
                });
                return false;
            } else if (this.form.duration == 0) {
                this.$bvToast.toast('La durée de la formation ne peut pas être égale à 0', {
                    title: 'Erreur',
                    variant: 'danger',
                    solid: true
                });
                return false;
            } else {
                return true;
            }
        },
        onSubmit(evt) {
            evt.preventDefault();
            if (!this.submit && this.checkData()) {
                this.submit = true;
                let data = new FormData();
                data.append('file1', this.file1);
                data.append('scorm_file', this.scorm_file);
                data.append('scorm', this.form.scorm === true ? 1 : 0);
                data.append('title', this.form.title);
                data.append('user_id', this.$store.getters.currentUser.id);
                data.append('description', this.form.description);
                data.append('miniature', this.form.miniature);
                data.append('summary', this.form.summary);
                data.append('linear', this.form.linear === true ? 1 : 0);
                data.append('catalog', this.form.catalog === true ? 1 : 0);
                data.append('text_certificate', this.form.text_certificate);
                data.append('duration', this.form.duration);
                data.append('teaser', this.form.teaser);
                data.append('theme', this.form.selectedTheme);
                data.append('classroom', this.form.classroom === true ? 1 : 0);
                data.append('max_intern', this.form.max_intern);

                ApiService.post(process.env.VUE_APP_API_URL + '/academy/classes', data)
                    .then((result) => {
                        if (result.data) {
                            this.$router.push({
                                name: 'classes/edit',
                                params: { id: result.data }
                            });
                            let classe_id = result.data;

                            let dataa = new FormData();
                            dataa.append('classe_id', classe_id);
                            dataa.append('customer_id', '304');

                            if (this.form.catalog === true) {
                                ApiService.post(process.env.VUE_APP_API_URL + '/academy/clientclasses', dataa).catch((error) => {
                                    this.errorMessage = error.message;
                                });

                                ApiService.get(process.env.VUE_APP_API_URL + '/users/customer/304').catch((error) => {
                                    this.errorMessage = error.message;
                                });
                            }
                        }
                    })
                    .catch(() => {
                        this.submit = false;
                        this.$nextTick(() => {
                            this.$bvToast.toast("Une erreur c'est produite lors de l'enregistrement de la classe.", {
                                title: "Une erreur s'est produite : ",
                                variant: 'danger',
                                solid: true
                            });
                        });
                    });
            }
        },
        onReset(evt) {
            evt.preventDefault();
            this.form.title = '';
            this.form.description = '';
            this.form.text_certificate = '';
            this.form.duration = '';
            this.form.teaser = '';
            this.form.scorm = false;
            this.scorm_file = '';
            this.form.linear = true;
            this.form.catalog = false;
            this.form.on = false;
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        onMiniatureChange() {
            const regex = /(^image)([/]{1})[a-zA-Z+]{1,}/g;
            if (this.$refs.miniature.files[0].type.match(regex)) {
                this.form.miniature = this.$refs.miniature.files[0];
                this.miniature = URL.createObjectURL(this.form.miniature);
            } else {
                confirm('Mauvais format de fichier');
            }
        },
        onSummaryChange() {
            this.file1Name = this.$refs.file1.files[0].name;
            this.file1 = this.$refs.file1.files[0];

            if (this.checkPdfSize(this.file1) === true && this.checkPdfType(this.file1) === true) {
                this.classData.summary = URL.createObjectURL(this.file1);
            }
        },
        checkPdfSize(file) {
            if (file.size < 2000000) {
                this.showPdfSizeAlert = false;
                return true;
            } else {
                this.showPdfSizeAlert = true;
                this.showPdfTypeAlert = false;
                return false;
            }
        },
        loadScorms() {
            this.error.scorm = false;
            this.scorm_file = this.$refs.scorm_file.files[0];
        },

        checkPdfType(file) {
            if (file.type === 'application/pdf') {
                this.showPdfTypeAlert = false;
                return true;
            } else {
                this.showPdfTypeAlert = true;
                this.showPdfSizeAlert = false;
                return false;
            }
        },
        isValidUrl(url) {
            let regexURL =
                /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.?\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[?6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1?,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00?a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u?00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
            if (regexURL.test(url) === true && (url.includes('youtube.com') || url.includes('youtu.be'))) {
                this.validURL = true;
                this.alertURL = false;
            } else if (url === '') {
                this.validURL = null;
                this.alertURL = false;
            } else {
                this.validURL = false;
                this.alertURL = true;
            }
        },
        setDefaultData() {
            if (this.form.scorm === true) {
                this.form.classroom = false;
                this.form.max_intern = null;
                this.form.linear = false;
            } else {
                this.scorm_file = '';
                this.$refs.scorm_file.value = null;
            }
        }
    }
};
</script>
